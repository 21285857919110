import { useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import Layout from "../../components/layout";
import TextSection from "../../components/text-section";
import ServiceHero from "../../components/service-hero";
import ReviewSection from "../../components/review-section";
import { GatsbyImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Certifications from "../../components/certifications";

const SPCMPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			DBEM1Img: wpMediaItem(title: { eq: "DBE-Meter-Image" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const heroImg = data.heroImg?.localFile.childImageSharp.gatsbyImageData;
	const DBEM1Img = data.DBEM1Img?.localFile.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Services",
				item: {
					url: `${siteUrl}/services`,
					id: `${siteUrl}/services`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Nimbus",
				item: {
					url: `${siteUrl}/services/smart-prepayment-and-card-meters`,
					id: `${siteUrl}/services/smart-prepayment-and-card-meters`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title="Smart Prepayment / Card Meters | Denis Bonnici Electricians Ltd"
				description="We install new Mid Approved prepay Smart Top Up Meters, a new way for Landlords to charge for energy use in rental properties."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/services/smart-prepayment-and-card-meters`,
					title:
						"Smart Prepayment / Card Meters | Denis Bonnici Electricians Ltd",
					description:
						"We install new Mid Approved prepay Smart Top Up Meters, a new way for Landlords to charge for energy use in rental properties.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
				twitter={{
					handle: "@denisbonnici",
					site: "@denisbonnici",
					cardType: "summary",
				}}
			/>
			<ServiceHero
				backgroundImage={heroImg}
				title="Smart Prepayment / Card Meters"
			/>
			<section className="py-md-7 pb-4">
				<TextSection
					title="Smart Prepayment / Card Meters"
					text={
						<div>
							<p>
								Denis Bonnici Electricians Ltd. acquired Nimbus Meters in 2012
								and have been servicing existing Landlords/Agents with the
								supply of Mid Approved prepay Smart Top Up Meters, replacement
								Ampy Card Meters, Meter Cards and Coin Meters.
							</p>

							<p>
								We install new Mid Approved prepay Smart Top Up Meters, a new
								way for Landlords to charge for energy use in rental properties.
								No cash/Meter Cards required. Energy is purchased by the Tenant
								through a secure payment Website, credit is instantly
								transferred to the Meter and payment direct to the Landlord’s
								bank account. Please contact us for more details on 01323
								639609.
							</p>
							<p>
								We also install replacement reconditioned Ampy, single phase,
								non-MID, pre-payment meters to provide “pay as you go”
								electricity. facilitated by programming cards. These meters and
								cards are available to purchase for Landlords/Agents only.
							</p>
							<p>
								The meters can be calibrated by inserting a “meterman”
								programming card, to enable settings to be registered at rate
								per kW hour. The programming card allows the Landlord/Agent to
								oset price per kW hour and Emergency credit. The price per kW
								hour should be calculated to include price paid for electricity,
								standing charge, VAT and cost to facilitate electricity via
								cards, to equate a figure that would not exceed costs, as is
								required.
							</p>
							<p>
								We supply the meter cards to landlords/agents only in
								denominations of £1.00, £5.00 & £10.00.
							</p>
							<div className="d-flex justify-content-center w-100">
								<GatsbyImage
									className=" my-5"
									image={DBEM1Img}
									alt={data.DBEM1Img?.altText}
								/>
							</div>
							<h3>Coin meters</h3>
							<p>We supply timed/non-timed coin meters with £1/£2 slots.</p>
							<p>
								Timed meters are ideally used for a communal area to serve
								washing machines, tumble dryers, hair dryers, snooker tables or
								heating etc.
							</p>
							<p>
								The insertion of coins starts the timer which turns on the
								device for a fixed period of time.
							</p>

							<h3>Installation</h3>
							<p>
								We are electrical contractors and would be happy to calibrate,
								or re-code your ampy meter.
							</p>
							<p>
								If you are not within our radius we advise that you contact a
								local electrician for installation and we would be happy to
								provide a detailed instruction on calibration.
							</p>
						</div>
					}
				/>
			</section>
			<section className="pt-6 pb-7 ">
				<Certifications />
			</section>
			<section className="pb-md-7 pb-4">
				<ReviewSection />
			</section>
		</Layout>
	);
};

export default SPCMPage;
